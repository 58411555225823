import type { LocaleObject } from '@nuxtjs/i18n'

export const locales: LocaleObject[] = [
  {
    code: 'en',
    file: 'en.json',
    name: 'English',
  },
  {
    code: 'fi',
    file: 'fi.json',
    name: 'Finnish',
  },
  {
    code: 'sv',
    file: 'sv.json',
    name: 'Swedish',
  },
  {
    code: 'de',
    file: 'de.json',
    name: 'German',
  },
]

export default {
  availableLocales: locales.map(l => l.code),
  fallbackLocale: 'en',
  fallbackWarn: false,
  missingWarn: false,
}
